@import "./src/functions";
html{
  // height: 100vh;
  font-size: 16px!important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slick-prev, .slick-next{
  left: 0;
  right: 0;
}
.swiper-button-next.swiper-button-disabled{
  display: none!important;
}
.swiper-button-prev.swiper-button-disabled{
  display: none!important;
}
.slick-initialized .slick-slide:last-child div{
  width: inRem(495px)!important;
}
.slick-slide img{
  height: inRem(285px);
  width: 100%;
  padding-right: 5px;
  object-fit: cover;
}
.gmnoprint, .gm-fullscreen-control{
  display: none;
}
.swiper-button-prev-step, .swiper-button-next-step{
  background-color: rgba(1, 4, 25, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 80%;
  width: inRem(40px);
  height: inRem(40px);
  outline: none;
  box-shadow: none;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  transform: rotate(180deg);
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-next-step:after, .swiper-container-rtl .swiper-button-prev-step:after{
  display: flex;
  width: 12px !important;
  content: "" !important;
  height: 19px !important;
  background-image: url('/assets/img/arrow_left.svg');
  background-repeat: no-repeat;
  position: relative;
  left: 5px;
}
.swiper-button-prev-step:after, .swiper-container-rtl .swiper-button-next-step:after{
  transform: rotate(180deg);
  display: flex;
  width: 12px !important;
  content: "" !important;
  height: 19px !important;
  background-image: url('/assets/img/arrow_left.svg');
  background-repeat: no-repeat;
  position: relative;
  right: 5px;
}
.swiper-button-prev-step:after, .swiper-button-next-step:after{
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
}
.swiper-button-next-step, .swiper-container-rtl .swiper-button-prev-step{
  right: -25px;
  left: auto;
}
.swiper-button-prev-step, .swiper-container-rtl .swiper-button-next-step{
  left: -27px;
  right: auto;
}
.swiper-button-prev{
  top: -13px !important;
  left: 345px!important;
  z-index: 99999 !important;
  width: 20px !important;
  height: 20px !important;
  transform: rotate(180deg) !important;
  box-shadow: none!important;
  outline: none!important;
}
.swiper-container{
  overflow: visible!important;
}
.swiper-button-next{
  top: -5px!important;
  right: -7px!important;
  width: 20px!important;
  height: 20px!important;
  z-index: 99999!important;
  box-shadow: none!important;
  outline: none!important;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
  width: 20px!important;
  content: ''!important;
  height: 20px!important;
  background-image: url('/assets/img/ico_small_arrow_right.svg');
  background-repeat: no-repeat;
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
  width: 20px!important;
  content: ''!important;
  height: 20px!important;
  background-image: url('/assets/img/ico_small_arrow_right.svg');
  background-repeat: no-repeat;
}
.blinking {
  animation-name: fade;
  animation-duration: 1s;

}



  @media (min-width: 601px) and (max-width: 1440px) {
    html {
      font-size: 14px!important;
    }
  }


@keyframes fade {
  from { opacity: 0; }
  to { opacity: 1;}
}
